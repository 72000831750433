
import './styles/app.scss';
import './libs/app';

console.log('Project started...');

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./sw.js').then(registration => {
      // console.log('SW registered: ', registration);
    }).catch(registrationError => {
      // console.log('SW registration failed: ', registrationError);
    });
  });
}

document.querySelector('.js-warning').style.display = 'none';

const btn = document.querySelector('footer button');
const corporate = document.querySelector('footer');

btn.addEventListener('click', evt => 
  corporate.classList.toggle('is-open'));